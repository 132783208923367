import "./LandingPage.css";
import Solution from "../components/Solution";
import DigitalIDIcon from "../components/DigitalIDIcon";
import MobilePaymentIcon from "../components/MobilePaymentIcon";
import MessagingIcon from "../components/MessagingIcon";
import VPNSolutionIcon from "../components/VPNSolutionIcon";
import Menu from "../components/Menu";
import { Button } from "antd";
import ArrowDownRightIcon from "../components/ArrowDownRightIcon";
import DPFLogo from "../components/DPFLogo";
import AddressIcon from "../components/AddressIcon";
import EmailIcon from "../components/EmailIcon";
import ContactForm from "../components/ContactForm";

const LandingPage = () => {
  return (
    <div
      style={{
        fontFamily: "Avenir Next",
      }}
    >
      <div className="bg-[#010106] text-[#FFFFFF] rounded-b-3xl">
        <div
          id="home"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/image-1.jpeg"})`,
          }}
          className="h-[1092px] bg-contain bg-no-repeat background-container rounded-b-3xl"
        >
          <nav className="flex flex-row justify-center items-center pt-5">
            <div className="w-1/3">
              <DPFLogo className="ml-14 cursor-pointer" />
            </div>
            <Menu className="w-1/3 h-[71px]" />
            <div className="w-1/3"></div>
          </nav>
          <div className="h-[304px] px-48 mb-36 mt-40">
            <p className="font-semibold text-3xl leading-9 text-center mb-8 text-[#70EEFF]">
              Dhanna Protocol Foundation
            </p>
            <p className="font-semibold text-8xl leading-[119px] text-center">
              Empowering Innovation Advancing Humanity
            </p>
          </div>
          <div className="flex flex-row mx-20">
            <video className="w-1/2 border border-[#70EEFF]" autoPlay muted>
              <source
                src={`${process.env.PUBLIC_URL}/dpf_1.mp4`}
                type="video/mp4"
              />
            </video>
            <div className="w-1/2">
              <div className="ml-20 mr-16 mt-6">
                <p>
                  We are a group of innovators, thinkers, and leaders united by
                  a commitment to using technology for social good. As a
                  nonprofit organization, our work is driven by the belief that
                  every technological advancement can catalyze positive change.
                  We collaborate with global experts and partners to design and
                  implement sustainable tech-based solutions that address the
                  pressing challenges of our time.
                </p>
                <Button
                  type="primary"
                  className="mt-10 bg-[#1159E4]"
                  icon={<ArrowDownRightIcon />}
                  iconPosition="end"
                  size="large"
                  shape="round"
                >
                  See More
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id="services" className="flex flex-row h-[747px] mx-14">
          <div className="w-1/2 mr-24">
            <div className="h-[454px] my-16">
              <p className="text-inherit text-7xl leading-[69px] font-semibold mb-20">
                Innovative Technologies
                <hr className="h-[2px] w-[117px] bg-[#70EEFF] mt-10"></hr>
              </p>
              <p className="text-inherit">
                Our innovative technologies encompass a range of solutions
                tailored for Privacy, Security and Efficiency. We offer an
                innovative VPN solution designed to surpass current market
                offerings by integrating advanced capabilities while ensuring we
                bridge the weaknesses of the existing VPN providers. We offer
                cutting-edge facial recognition for digital ID verification,
                streamline mobile payment for banks and insurers, and ensure
                robust communication with our chat system capable of functioning
                both offline and online.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/image-2.png"
              })`,
            }}
            className="w-1/2 bg-contain bg-no-repeat"
          ></div>
        </div>
        <div className="flex flex-row px-10 gap-3 pb-20 mx-auto max-w-max">
          <Solution
            icon={<DigitalIDIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">Digital ID</p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Facial Recognition on Edge
              </p>
            }
          />
          <Solution
            icon={<MobilePaymentIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">
                Mobile Payments
              </p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Banks, MFS, Insurers
              </p>
            }
          />
          <Solution
            icon={<MessagingIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">Messaging</p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Supports both online and offline
              </p>
            }
          />
          <Solution
            icon={<VPNSolutionIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">
                VPN Solutions
              </p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Robust privacy and security
              </p>
            }
          />
        </div>
      </div>
      <div id="contact">
        <div className="flex flex-row px-32 pt-36 pb-28">
          <div className="w-1/2 pr-10">
            <p className="font-semibold text-7xl leading-[59px]">Contact Us</p>
            <hr className="h-[2px] w-[117px] bg-[#1159E4] my-10" />
            <p className="font-medium text-lg leading-9">
              For more information about our technologies and services or to
              engage with our team, please reach out through the following
              channels:
            </p>
            <div className="my-10">
              <p className="font-semibold text-3xl m-2">
                Dhanna Protocol Foundation
              </p>
              <div className="my-2 ml-2 flex flex-col justify-center gap-3">
                <div className="flex flex-row">
                  <AddressIcon className="mt-1" />
                  <p className="ml-3">
                    Samuel Lewis Avenue & 55th Street, PH SL55 Building, <br />
                    21st Floor Panama, Republic of Panama
                  </p>
                </div>
                <div className="flex flex-row">
                  <EmailIcon className="my-auto" />
                  <p className="ml-3">admin@dhannafoundationprotocol.com</p>
                </div>
              </div>
            </div>
            <p>
              Additionally, you can fill out our contact form for quick
              inquiries or further information. We look forward to hearing from
              you!
            </p>
          </div>
          <div className="w-1/2 pl-10 relative">
            <div className="w-[109px] h-[109px] border-[#1159E4] border-t-2 border-l-2 absolute top-[-55px] left-[-15px]"></div>
            <ContactForm className="shadow-2xl w-full h-full rounded-2xl" />
            <div className="w-[109px] h-[109px] border-[#1159E4] border-b-2 border-r-2 absolute bottom-[-55px] right-[-55px]"></div>
          </div>
        </div>
      </div>
      <div className="bg-[#010106] text-[#FFFFFF] px-20 pt-24 pb-12">
        <div className="flex flex-row justify-between">
          <DPFLogo />
          <div className="flex flex-row justify-between items-center gap-10">
            <p className="font-semibold text-xl">Home</p>
            <p className="font-semibold text-xl">Services</p>
            <p className="font-semibold text-xl">Contact Us</p>
          </div>
        </div>
        <hr className="h-[1px] bg-white my-10"></hr>
        <div className="flex flex-row justify-between px-5">
          <p className="w-[800px] font-normal text-sm leading-[22.4px]">
            2024. All rights reserved.
          </p>
          <p className="font-normal text-sm leading-[22.4px]">
            Privacy Policy | Terms&conditions
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
